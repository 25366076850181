import { useDispatch } from "react-redux";
import { Box, Typography, Divider, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { deleteCurrentReport } from "../../actions/reportsActions";
import { useParams } from "react-router-dom";

const { REACT_APP_HOST_URL } = process.env;

const ReportItem = ({ filter, ...props }) => {
  let { investmentId } = useParams();
  const dispatch = useDispatch();

  const handleDelete = (e) => {
    e?.preventDefault();
    const query = { projectRef: props.projectRef };
    props.associateRef ? (query.associateRef = props.associateRef) : null;
    filter ? (query.type = filter) : null;
    dispatch(
      deleteCurrentReport({
        id: props._id,
        query: { ...query, associateInvestmentId: investmentId },
      })
    );
  };

  return (
    <>
      <Box
        sx={{
          py: "1rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <a
            href={`${REACT_APP_HOST_URL}/images/reports/${props.path}`}
            target="_blank"
            rel="noreferrer"
          >
            {props.name}
          </a>
          <Typography variant="caption" sx={{ display: "block" }}>
            Fecha {new Date(`${props.date}`).toLocaleDateString("es-MX")}
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={() => props.handleEditReport(props.report)}>
            <Edit />
          </IconButton>
          <IconButton onClick={handleDelete}>
            <Delete />
          </IconButton>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default ReportItem;
